<template>
  <div class="applicationPage">
    <HeadInfo :hide-right-items="true" />
    <div class="applicationSearch">
      <div class="applicationHead">
        <!-- <div>
          <img
            :src="`${imgUrl}/anon/system/forgot-image`"
            style="max-width: 216px; max-height: 150px"
            alt="forgotuser"
          >
        </div> -->
        <div>
          <!-- <div class="applicationTitle">
            {{ clientName }}
          </div>
          <div class="applicationSubTitle">
            {{ subTitle }}
          </div> -->
          <div
            class="applicationDescription"
            v-html="desc"
          />
        </div>
      </div>
      <div>
        <div
          class="noNavTabCard"
          v-if="formInputItems.extFields.length > 0"
        >
          <div
            class="cardHead"
            style="margin-bottom: 15px"
          >
            {{ $g("searchElector") }}
          </div>
          <k-alert-msg ref="lblMsg" />
          <KValidationObserver ref="observer">
            <div>
              <k-custom-fields
                ref="customFields"
                v-model="formInputItems"
                :plaintext="false"
              />
              <k-form-group
                v-for="(item, index) in popwindowTextBoundaryTypeArray"
                :key="index"
                :label-class="item.isMandatory ? `require-mark` : ``"
                :label-suffix="item.displayName"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :no-locale="true"
                label-for="noneBind"
              >
                <KValidationProvider
                  :rules="{ required: item.isMandatory }"
                  v-slot="{ errors }"
                  slim
                  :name="`popwindowText${index}`"
                >
                  <k-alert
                    :show="errors.length > 0"
                    variant="warning"
                  >
                    {{ errors[0] }}
                  </k-alert>
                  <div
                    class="input-search"
                    @click="popwindowTextClick(item, index)"
                  >
                    <k-form-input
                      :ref="`popwindowText${index}`"
                      v-model="item.inputValue"
                      type="text"
                      :plaintext="item.isOnlyView"
                      disabled
                    />
                    <b-icon icon="search" />
                  </div>
                </KValidationProvider>
              </k-form-group>
            </div>
            <k-form-group
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-for="noneBind"
            >
              <div
                class="alert alert-warning"
                v-if="isManMachine"
              >
                {{ $g("validate.required") }}
              </div>
              <ReCaptcha
                @validate="validate"
                @expired="resetCaptcha"
                class="cardBody border-0"
              />
            </k-form-group>

            <k-form-group
              label-align-md="right"
              label-cols-md="3"
              content-cols-lg="6"
              content-cols-md="7"
              label-for="noneBind"
              class="wapBtnCenter"
            >
              <div class="d-flex flex-wrap justify-content-between">
                <k-button
                  @click="onSearch"
                  class="w-150"
                  variant="primary"
                >
                  buttons.search
                </k-button>
                <k-button
                  @click="onClear"
                  class="w-150"
                  variant="utility"
                >
                  buttons.clear
                </k-button>
                <k-button
                  @click="backAction"
                  variant="utility"
                  class="w-150 btnBack"
                >
                  buttons.back
                </k-button>
              </div>
            </k-form-group>
          </KValidationObserver>
        </div>
        <k-popwindow
          id="model-popwindowText"
          :title="popwindowTextTitle"
          @ok="onPopDivisionSelected"
        >
          <PopWindowText
            ref="popwindowText"
            :get-api="popwindowTextApi"
            :condition="popwindowTextApiCondition"
            :show-popwindow-text-enum="ShowPopwindowTextEnum"
            :show-popwindow-text="ShowPopwindowText"
          />
        </k-popwindow>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  getSearchVoterKeys,
  searchElectorVoter,
  getBoundaryPage,
  getVoterBoundaryType,
  getSearchSubdivisionPage,
  getStreetType,
  getStateType,
  getStreetPost,
  getClientName
} from "../../api/application";
import PopWindowText from "./components/popwindowText";
import { getPortalPageSetting } from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import {
  globalEnv,
  ReCaptcha,
  getCurPageConfigbyRoutePath
} from "fe-infrastractures";
import { searchVoterResultKey } from "@/utils/storeageKey";

export default {
  data() {
    let curPageConfig = getCurPageConfigbyRoutePath(
      "workerPortal",
      this.$route.path
    );
    return {
      residentialStreetNameInputVIF:
        curPageConfig?.residentialStreetNameInput?.vIf ?? false,
      formInputItems: {
        extFields: []
      },
      condition: {},
      popwindowTextBoundaryTypeArray: [],
      popwindowTextArray: [],
      popwindowTextTitle: "",
      popwindowTextApi: null,
      popwindowTextApiCondition: [],
      popwindowTextInputRef: "",
      popwindowTextInputIndex: 0,
      ShowPopwindowText: 0,
      ShowPopwindowTextEnum: {
        None: 0,
        Subdivision: 1,
        BoundaryType: 2
      },
      // select
      streetTypes: [],
      streetPosts: [],
      stateTypes: [],
      selectedId: "",

      clientName: "clientName",
      desc: "desc",
      subTitle: this.$route.query.title,
      isValidateRecaptcha: false,
      isManMachine: false,
      localEnv: true,
      imgUrl: globalEnv.VUE_APP_STD_URL + "/" + globalEnv.VUE_APP_SYSTEM_PATH,
    };
  },
  created: function () {
    this.bindFormInput();
    this.bindStreetTypes();
    this.bindStateType();
    this.bindStreetPosts();
    getClientName(this.$route.query.id).then((data) => {
      this.clientName = data.data.clientName;
    });
    getPortalPageSetting(pageNameEnum.ApplicationSearchVoter).then((data) => {
      this.desc = data.data.description?.replace("$WorkerType$",this.subTitle);
    });

    sessionStorage.removeItem(searchVoterResultKey)
  },
  components: {
    PopWindowText,
    HeadInfo,
    FooterInfo,
    ReCaptcha
  },
  methods: {
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    popwindowTextClick(item, input) {
      this.selectedId = item.id;
      this.popwindowTextInputRef = `popwindowText${input}`;
      this.popwindowTextInputIndex = input;
      this.ShowPopwindowText = item.showPopwindowTextEnum;
      this.popwindowTextApiCondition = {
        ...item.condition
      };
      this.popwindowTextApi = item.api;
      this.$bvModal.show("model-popwindowText");
    },
    onPopDivisionSelected() {
      let item = this.$refs.popwindowText.getSelected()[0];
      let data = this.switchShowPopwindowText(this.ShowPopwindowText, item);
      this.$set(
        this.popwindowTextBoundaryTypeArray[this.popwindowTextInputIndex],
        "inputValue",
        data.name
      );
    },
    switchShowPopwindowText(e, item) {
      let data = {
        name: ""
      };
      switch (e) {
        case this.ShowPopwindowTextEnum.Subdivision:
          data.name = item.divisionName;
          this.condition.subdivisionId = item.subdivisionId;
          break;
        case this.ShowPopwindowTextEnum.BoundaryType:
          data.name = item.boundaryName;
          if (this.condition.boundaryList) {
            this.condition.boundaryList.forEach((c, index, arr) => {
              if (c.boundaryTypeId == this.selectedId) {
                arr.splice(index, 1);
              }
            });
          }
          this.boundaryTypeSetConditionBoundaryList({
            boundaryId: item.boundaryId,
            boundaryTypeId: this.selectedId
          });
          break;
        default:
          break;
      }
      return data;
    },
    boundaryTypeSetConditionBoundaryList(item) {
      if (!this.condition.boundaryList) {
        this.condition.boundaryList = [{ ...item }];
      } else {
        this.condition.boundaryList.push({ ...item });
      }
    },
    bindFormInput() {
      getSearchVoterKeys().then((result) => {
        this.setBoundaryType(result);
        let data = result.data.filter(
          (c) => c.key != "Boundary" && c.key != "Subdivision" && c.activate
        );
        data.forEach((c) => {
          this.inputFor(c);
        });
        this.formInputItems.extFields = [...data];
      });
    },
    inputFor(c) {
      c.options = this.getSwitchSelectOptions(c.key);
      c.type = c.fieldType == 0 ? 1 : c.fieldType;
      c.fieldName = c.key;
      c.isMandatory = c.required;
      c.isOnlyView = false;
      c.displayName = c.label;
      if (
        this.residentialStreetNameInputVIF &&
        c.key == "ResidentialStreetName"
      ) {
        c.placeholder = "streetNamePlaceholder";
      }
    },
    buildBoundaryType(Boundary) {
      getVoterBoundaryType().then((result) => {
        result.data.forEach((c) => {
          Boundary.label = c.typeCode;
          Boundary.condition = { boundaryTypeId: c.id };
          Boundary.showPopwindowTextEnum =
            this.ShowPopwindowTextEnum.BoundaryType;
          c = { ...c, ...Boundary };
          this.inputFor(c);
          this.popwindowTextBoundaryTypeArray.push(c);
        });
      });
    },
    setBoundaryType(result) {
      let Boundary = result.data.find((c) => c.key == "Boundary" && c.activate);
      let Subdivision = result.data.find(
        (c) => c.key == "Subdivision" && c.activate
      );
      if (Subdivision) {
        Subdivision.api = getSearchSubdivisionPage;
        Subdivision.showPopwindowTextEnum =
          this.ShowPopwindowTextEnum.Subdivision;
        this.popwindowTextArray.push(Subdivision);
        let s = {
          typeCode: "Subdivision",
          displayName: "Subdivision",
          ...Subdivision
        };
        this.inputFor(s);
        this.popwindowTextBoundaryTypeArray.push(s);
      }
      if (Boundary) {
        Boundary.api = getBoundaryPage;
        this.popwindowTextArray.push(Boundary);
        this.buildBoundaryType(Boundary);
      }
    },
    getSwitchSelectOptions(text) {
      let options = [];
      switch (text) {
        case "ResidentialStreetType":
          options = this.streetTypes;
          break;
        case "ResidentialStreetPre":
          options = this.streetPosts;
          break;
        case "ResidentialStreetPost":
          options = this.streetPosts;
          break;
        case "ResidentialState":
          options = this.stateTypes;
          break;
        default:
          break;
      }
      return options;
    },
    getCondition() {
      let c = this.$refs.customFields.getInputValue(
        {},
        this.formInputItems.extFields
      );
      return {
        ...c,
        ...this.condition
      };
    },
    onClear() {
      this.popwindowTextInputRef = "";
      this.popwindowTextInputIndex = 0;
      this.ShowPopwindowText = 0;
      this.condition = {};
      this.formInputItems.extFields.forEach((e) => {
        e.value = "";
      });
      this.$refs.customFields.setInputValue();
      this.$refs.observer.reset();
      this.popwindowTextBoundaryTypeArray.forEach((e) => {
        this.$set(e, "inputValue", null);
      });
    },
    async onSearch() {
      var isRecaptchaValid = true;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isRecaptchaValid = this.isValidateRecaptcha;
      }
      if (isRecaptchaValid) {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          searchElectorVoter(this.getCondition()).then((result) => {
            const data = result.data || [];
            if (data.total != 1) {
              this.$refs.lblMsg.message(2, result.message);
            } else {
              this.onClose(data.rows[0]);
            }
          });
        }
      }
    },
    onClose(item) {
      sessionStorage.setItem(searchVoterResultKey, JSON.stringify(item));
      this.$router.push({
        name: "form",
        query: this.$route.query
      });
    },
    bindStreetTypes() {
      return getStreetType({}).then((data) => {
        const items = data.data;
        this.streetTypes = items || [];
      });
    },
    bindStateType() {
      return getStateType({}).then((data) => {
        const items = data.data;
        this.stateTypes = items || [];
      });
    },
    bindStreetPosts() {
      return getStreetPost({}).then((data) => {
        const items = data.data;
        this.streetPosts = items || [];
      });
    },
    backAction() {
      this.$router.push({
        name: "application"
      });
    }
  }
};
</script>

